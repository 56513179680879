import { graphql, PageProps } from 'gatsby'

import PaginatedBlog from '@components/PaginatedBlog'

import { WpPage, WpPostConnection, WpCategory } from '@_types/graphql-types'

type BlogProps = {
  posts: WpPostConnection
  featured: WpPostConnection
  seo: WpPage
  category: WpCategory
}

type ContextType = {
  pageNumber: number
}

function CategoryPage({
  data: { posts, seo, category },
  pageContext
}: PageProps<BlogProps, ContextType>) {
  return (
    <PaginatedBlog
      posts={posts}
      seo={seo}
      context={pageContext}
      category={category}
    />
  )
}

export default CategoryPage

export const pageQuery = graphql`
  query getCategorizedPosts($categorySlug: String, $limit: Int!, $skip: Int!) {
    seo: wpPage(isFrontPage: { eq: true }) {
      seo {
        ...SEO
      }
    }
    category: wpCategory(slug: { eq: $categorySlug }) {
      name
      slug
    }
    posts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $categorySlug } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostArchiveFields
          acfPostParams {
            postviews
          }
          featuredImage {
            node {
              ...FeaturedImageFields
            }
          }
        }
      }
    }
  }
`
